import React from 'react'
import StrapiImage from '../types/StrapiImage'
import Image from './Image'

interface NewsItemProps {
  newsItem: {
    Title: string
    body: string
    image: StrapiImage
    slug: string
    id: number
    author: {
      data: {
        attributes: {
          name: string
        }
      }
    }
  }
  className?: string
}

const NewsItem = ({ newsItem, className }: NewsItemProps) => (
  <a
    key={newsItem.id}
    className={`newsItem ${className}`}
    href={`/posts/${newsItem.slug}`}
  >
    <Image
      className="newsItem__image"
      image={newsItem.image}
      format="small"
    ></Image>
    <p className="newsItem__title">{newsItem.Title}</p>
    <p className="newsItem__body">{newsItem.body?.slice(0, 100)}...</p>
    <p className="newsItem__author">
      {newsItem.author?.data?.attributes?.name}
    </p>
  </a>
)

export default NewsItem
