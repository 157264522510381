import React from 'react'
import StrapiImage from '../../types/StrapiImage'
import PageWidth from '../PageWidth'
import NewsItem from '../NewsItem'

interface NewsProps {
  title: string
  subtitle: string
  posts: {
    data: {
      attributes: {
        Title: string
        body: string
        image: StrapiImage
        slug: string
        id: number
        author: {
          data: {
            attributes: {
              name: string
            }
          }
        }
      }
      id: number
    }[]
  }
}

const News = ({ posts }: NewsProps) => (
  <PageWidth className="blockNews">
    <div className="blockNews__list">
      {posts.data.map((post) => (
        <NewsItem
          key={post.id}
          newsItem={post?.attributes}
          className="blockNews__listItem"
        />
      ))}
    </div>
  </PageWidth>
)

export default News
